(function(wnd){
    function CompanyForm(form, params) {
        try {
            if (!form) {
                throw 'Form is required!';
            }

            this.form = form;
            if (params) {
                this.setParams(params);
            }

            this.init();
        } catch (error) {
            console.error(error);
        }
    };

    CompanyForm.prototype = {
        form: undefined,
        notyf: undefined,
        button: undefined,
        sendedForm: false,
        locked: true,
        uploadContainer: undefined,
        fileLabel: undefined,
        fileLabelText: undefined,
        customFile: undefined,
        init: function() {
            this.setElements();
            this.addDOMEvents();
        },
        setElements: function() {
            this.notyf = new Notyf({
                delay:5000,
                alertIcon: 'notyf-error-icon',
                confirmIcon: 'notyf-confirm-icon'
            });
            this.button = this.form.find('[data-purpose="save-button"]');
            this.uploadContainer = this.form.find('.updated-files');
            this.fileLabel = this.form.find('.file-input-label');
            this.fileLabelText = this.fileLabel.find('.text');
            this.lockedRemodal = $('[data-remodal-id=locked-remodal]').remodal();
            this.customFile = this.form.find('.custom-file-outer');

            if (this.locked === 'true') {
                $.each(this.form.serializeArray(), function(index, value){
                    
                    field = $('[name="' + value.name + '"]');
                    
                    field.attr('readonly', 'readonly');

                    field.addClass('disabled');
                    field.parent().addClass('disabled');

                    if (field.attr('type') == 'radio' || field.attr('type') == 'checkbox') {
                        field.attr('disabled', true);
                    }
                });   
                
                this.lockedRemodal.open();
            }
        },
        addDOMEvents: function() {
            var _self = this;

            if (this.button.length > 0) {
                this.button.click(function(){
                    _self.sendForm();
                });
            }

            $(document).on('change', '#companyFile', function(){
                _self.uploadFile(this.files);
            });

            this.form.find('.deleteRow').on('click', function(){                          
                _self.deleteFile($(this));
            });
        },
        addROWDOMevents: function(row){
            var _self = this;

            row.find('.deleteRow').on('click', function(){                          
                _self.deleteFile($(this));
            });
        },
        setParams: function(params) {
            if (params.locked) {this.locked = params.locked;}
        },
        sendForm: function() {
            var _self = this;

            if (!this.sendedForm) {
                this.sendedForm = true;
                
                $.ajax({
                    url:this.form.attr('action'),
                    dataType:'json',
                    type:'post',
                    data: _self.form.serialize(),
                    beforeSend: function() {
                        _self.form.find('.has-error').removeClass('has-error');
                    },
                    success: function(response) {
                        if (response.success) {
                            if ((response.msg).trim() !== '') {
                                _self.notyf.confirm(response.msg);
                            }
                            setTimeout(function(){
                                document.location.href=response.url
                            }, 2000);
                        } else {
                            if (typeof response.errors == 'object') {
                                $.each(response.errors, function(key, value) {
                                    if (value.trim() !== '') {
                                        _self.notyf.alert(value);
                                    }

                                    var input = _self.form.find('[name="'+key+'"]');
                                    console.log(input);
                                    if (input.length > 0) {
                                        input.parent().addClass('has-error');
                                        input.addClass('has-error');
                                    }
                                });
                            }

                            setTimeout(function() {
                                _self.sendedForm = false;
                            }, 500);
                        }
                    },
                });
            }
        },
        uploadFile: function(files) {
            var _self = this;
            var data = new FormData();

            var totalfiles = files.length;
            for (var index = 0; index < totalfiles; index++) {
                data.append("files[]", files[index]);
            }

            $('#regButton').fadeOut(300);

            $.ajax({
                url:'ajax/uploadFile/',
                type:'post',
                dataType:'json',
                data: data,
                cache: false,
                contentType: false,
                processData: false,
                success: function(response) {
                    if (response.success) {                                                

                        if (Object.keys(response.tmp_img).length > 0) {
                            $.each(response.tmp_img, function(key, value) {
                               _self.createUploadRow(key, value);
                            });
                        }

                        $('#companyLogo').val('');

                        $('#regButton').fadeIn(300);                        

                    } else {
                        $('#regButton').fadeIn(300);
                    }
                }
            });
        },
        createUploadRow: function(filevalue, fileName) {
            var row = $('<div/>', {
                'class': 'file-row'
            });

            var nameSpan = $('<img/>', {
                'class': 'file-name',
                'src': base_url+'assets/file_tmp/'+filevalue,                
                'alt': fileName
            });

            var fileHidden = $('<input/>', {
                'value': filevalue,
                'name': 'file_hidden[]',
                'type': 'hidden'
            });

            
            var deleteSpan = $('<span/>', {
                'class': 'deleteRow',
                'html': svg('trash-icon')
            });

            row.append(nameSpan);
            row.append(fileHidden);
            row.append(deleteSpan);

            row.append($('<input/>', {
                'value': fileName,
                'name': 'file_names[]',
                'type': 'hidden'
            }));

            this.customFile.html(row);

            this.addROWDOMevents(row);

        },
        deleteFile: function(row) {
            row.parent().remove();

            var upload = '<label class="file-input-label field" for="companyFile"><span class="button empty-button">Choose file</span><span class="text">JPG or PNG smaller than 5 MB</span></label> <input type="file" id="companyFile" name="companyFile"/>';

            this.customFile.html(upload);
        }
    };

    wnd.CompanyForm = CompanyForm;
})(window);